<template>
  <b-container fluid>
    <b-row class="mb-3">
        <b-col cols="12">
            <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Marketing Cost</h1>
        </b-col>
    </b-row>
    <b-row class="mb-3">
        <b-col cols="12">
            <b-form @submit.prevent="submit">
                <b-row v-if="!isLoading">
                    <b-col cols="12">
                        <b-row>
                            <b-col>
                                <b-card>
                                    <h4 class="mb-4">Product Details</h4>
                                    <b-form-group label="Name" :invalid-feedback="validationInvalidFeedback(errors.errors, 'name')">
                                        <b-form-input :disabled="isSaving" type="text" :state="validationState(errors.errors, 'name')" v-model="marketingCost.name" />
                                    </b-form-group>
                                    <b-form-group label="Date" :invalid-feedback="validationInvalidFeedback(errors.errors, 'date')">
                                        <b-form-datepicker :disabled="isSaving" type="text" :state="validationState(errors.errors, 'date')" v-model="marketingCost.date" />
                                    </b-form-group>
                                    <b-form-group label="Amount" :invalid-feedback="validationInvalidFeedback(errors.errors, 'amount')">
                                        <b-form-input :disabled="isSaving" type="text" :state="validationState(errors.errors, 'amount')" v-model="marketingCost.amount" />
                                    </b-form-group>
                                </b-card>
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col class="d-flex flex-row" cols="12" md="4" offset-md="8">
                                <b-button block class="align-self-end mb-3" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row v-if="isLoading">
                    <b-col cols="12">
                        <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
                    </b-col>
                </b-row>
            </b-form>
        </b-col>
    </b-row>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [validation],
  data: () => ({
      marketingCost: {
        id: null,
        name: null,
        date: null,
        amount: null,
      }
  }),
  created () {
    if(this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }
  },
  computed: {
    ...mapGetters('marketing-costs', ['errors', 'isLoading', 'isSaving', 'single'])
  },
  methods: {
    ...mapActions('marketing-costs', ['fetch', 'save']),
    submit () {
      this.save(this.marketingCost).then(response => {
          console.log({response})
        if([ 200, 201 ].includes(response.status)) this.$router.push({
          name: 'dash.marketing-costs.list',
          params: {
            notifications: [{
              variant: 'success',
              title: `Marketing Cost #${response.data.id}`,
              subtitle: `${response.data.name} - £${response.data.amount}`,
              text: `The marketing cost has been ${this.$route.params.id ? 'updated' : 'created'} successfully.`,
              timeout: 5000
            }]
          }
        })
      })
    }
  },
  watch: {
    single (newUser) {
      this.marketingCost = this.single
    }
  }
}
</script>

<style>

</style>
