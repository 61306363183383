import first from 'lodash/first'
import pick from 'lodash/pick'
import has from 'lodash/has'

export default {
  methods: {
    validationInvalidFeedback (errors, key) {
      return first(pick(errors, key)[key])
    },
    validationState (errors, key) {
      return has(errors, key) ? false : null
    },
    validationGroupState (errors, key) {
      if(errors == null) return null
      return Object.keys(errors).some(errKey => { return errKey.startsWith(key) })
    },
  }
}
